<template>
	<div data-route>
		<div data-element="main">
			<div data-element="deal-library">
				<page-header
					heading="Browse deals"
					:action="getPageHeaderAction"
				/>
				<deal-library
					:isInActivity="false"
				/>
			</div>
		</div>
	</div>
</template>

<script>

	import DealLibrary  from '@/components/dealLibrary/DealLibrary';
	import PageHeader   from '@/components/ui/PageHeader';

	export default {
		metaInfo: {
			title: 'Deal library'
		},
		components: {
			PageHeader,
			DealLibrary
		},
		computed: {
			getPageHeaderAction () {
				return {
					text: 'Import PBN',
					route: '/teaching/deals/create'
				};
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-element='deal-library'] {
	}
</style>
